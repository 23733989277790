import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 0,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Inter, sans-serif",
    secondary: "Bebas Neue, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#004d39",
      light: "#FFFFFF",
      dark: "#080808",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#2E8828",
      light: "#23661B",
      dark: "#061E04",
    },
    tertiary: {
      main: "#0F130F",
      dark: "#0F130F",
      light: "#E9E9E9",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#1A4D13",
      content: "#A1A1A1",
      tertiary: "#15372C",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"DM Sans", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
